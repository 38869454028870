.ShopifyIntegration {
  display: flex;
  justify-content: center;
  background: #f3f3f3;
}

.ShopifyIntegration__container {
  max-width: 1000px;
}

.Banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.BackButton {
  width: 30px;
}
.BackButton:hover {
  cursor: pointer;
}

.Header {
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
}
.Subheader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
.SubheaderParagraph {
  width: 500px;
}

.StepFirstLayout {
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
}
.StepFirstLayout-number {
  color: #6d89b6;
  text-decoration: underline;
  font-size: 18px;
  margin-bottom: 20px;
}
.StepFirstLayout-title {
  font-size: 28px;
  margin-bottom: 40px;
}
.StepFirstLayout-content {
  width: 700px;
}
.StepFirstLayout-content-img {
  max-width: 700px;
}

.StepSecondLayout {
  padding: 100px 0;
}

.floatingContent {
  top: 0;
  left: 0;
  padding: 10px;
  width: 100%;
  height: 100vh;
  background-color: #99999988;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

img.zoom {
  cursor: zoom-in;
  transition: transform .2s;
}

img.zoom:hover {
  transform: scale(1.01);
  box-shadow: 0 0 50px #999;
}

.floatingContent img {
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
}

.StepSecondLayout-number {
  color: #6d89b6;
  text-decoration: underline;
  font-size: 18px;
  margin-bottom: 20px;
}

.StepSecondLayout-title {
  font-size: 28px;
  margin-bottom: 40px;
}

.StepSecondLayout-content-img {
  width: 100%;
}

.code {
    text-align: left;
    border: 1px solid #999;
    background-color: #ddd;
    border-radius: 5px;
    display: block;
    padding: 10px;
    margin-top: 5px;
}

.validate-jwt li {
    text-align: left;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1100px) {
  .Banner {
    margin: 20px 20px 0 40px;
  }

  .Header {
    flex-direction: column;
    margin-bottom: 100px;
  }
  .SubheaderParagraph {
    width: 80%;
  }

  .StepFirstLayout {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .StepFirstLayout-content {
    width: 300px;
  }
  .StepFirstLayout-content-img {
    width: 300px;
  }

  .StepSecondLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .StepSecondLayout-content-img {
    width: 300px;
  }
}
